// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  updateDoc,
  doc,
  setDoc,
  limit,
  startAfter,
  getDocs,
  endBefore,
  getDoc,
} from "firebase/firestore";
// import { GoogleAuth } from "google-auth-library";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useEffect, useState } from "react";

let lastMsg;
export const removeLastMsg = () => {
  lastMsg = undefined;
};

//     apiKey: process.env.REACT_APP_APIKEy,
//     authDomain:  process.env.REACT_APP_AUTHDOMAIN,
//     projectId:  process.env.REACT_APP_PROJECTID,
//     storageBucket:  process.env.REACT_APP_STORAGEBUCKET,
//     messagingSenderId:  process.env.REACT_APP_messagingSenderId,
//     appId:  process.env.REACT_APP_APPID,
//     measurementId: process.env.REACT_APP_MEASUREMENTID
// });

const firebaseConfig = {
  apiKey: "AIzaSyCN3fe6hJjK4FjOAX5Hqn_GYEOhqW77JF4",
  authDomain: "green-cadb3.firebaseapp.com",
  projectId: "green-cadb3",
  storageBucket: "green-cadb3.appspot.com",
  messagingSenderId: "809334948932",
  appId: "1:809334948932:web:f0dbafabc5043debe7e811",
  measurementId: "G-6KTJVMVWJ9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// Firebase Storage initialization
const storage = getStorage();
// ------------------------------------------------------------------------------------------------
export const messaging = getMessaging(app);
// ------------------------------------------------------------------------------------------------

export const requestToken = async (setFcmToken = () => {}) => {
  let currentToken = "";
  // requestPermission()
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      currentToken = await getToken(messaging, {
        vapidKey:
          "BMJDC81lhHQQsbiurzGoJvR-3ANWlGLEMMHN7-azuIbjuFP0GMj2YwEsg41x4IZ7jnpogehc8wfv6hXQFfpa6r0",
      });
      if (currentToken) {
        console.log("Notifications  Allowed");

        setFcmToken(currentToken);
      } else {
        console.log("Notifications Not Allowed");
      }
    } catch (error) {
      console.log(error);
    }
  }
};

// ------------------------------------------------------------------------------------------------
export const useFirebaseImage = (imageName, type) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!imageName) return;
    setLoading(true);
    const imagePath =
      type === "account"
        ? `${process.env.REACT_APP_FIREBASE_ACCOUNTS_IMAGES}/${imageName}`
        : type === "car"
        ? `${process.env.REACT_APP_FIREBASE_CARS_IMAGES}/${imageName}`
        : type === "class"
        ? `${process.env.REACT_APP_FIREBASE_CLASSES_IMAGES}/${imageName}`
        : "";
    const fetchImageUrl = async () => {
      const imageRef = ref(storage, imagePath);
      try {
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      } catch (err) {
        setImageUrl(null);
      }
      setLoading(false);
    };
    fetchImageUrl();
  }, [imageName, type]);

  return { imageUrl, loading };
};

export const useFirebaseImageUrls = (imageNames, type) => {
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const urls = {};
        await Promise.all(
          imageNames.map(async (name) => {
            const path =
              type === "account"
                ? `${process.env.REACT_APP_FIREBASE_ACCOUNTS_IMAGES}/${name}`
                : type === "car"
                ? `${process.env.REACT_APP_FIREBASE_CARS_IMAGES}/${name}`
                : type === "class"
                ? `${process.env.REACT_APP_FIREBASE_CLASSES_IMAGES}/${name}`
                : "";
            const imageRef = ref(storage, path);
            try {
              const url = await getDownloadURL(imageRef);
              urls[name] = url; // Store URL using the extracted image name as the key
            } catch (err) {
              urls[name] = null;
            }
          })
        );
        setImageUrls(urls);
      } catch (err) {}
    };

    if (imageNames.length > 0) {
      fetchImageUrls();
    }
  }, [imageNames, type]);

  return { imageUrls };
};

export const uploadImage = (file, type, name) => {
  console.log(type, file, name);
  console.log(process.env.REACT_APP_FIREBASE_CARS_IMAGES);
  return new Promise((resolve, reject) => {
    const path =
      type === "account"
        ? `${process.env.REACT_APP_FIREBASE_ACCOUNTS_IMAGES}/${name}`
        : type === "car"
        ? `${process.env.REACT_APP_FIREBASE_CARS_IMAGES}/${name}`
        : type === "class"
        ? `${process.env.REACT_APP_FIREBASE_CLASSES_IMAGES}/${name}`
        : "";
    // Create a storage reference
    const storageRef = ref(storage, path);

    // Upload the file
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Monitor the upload process
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // You can track the progress here if needed
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      () => {
        // On successful upload, get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};
// ------------------------------------------------------------------------------------------------
const getFirebaseDoc = async (collictionName, id) => {
  const docRef = doc(db, collictionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};
// ------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------=

function getMessages(firebaseCollection, customer, msgs, callback) {
  let limitMsgs = process.env.REACT_APP_firebase_msgs_page_size;
  let q = !!msgs?.length
    ? query(
        collection(db, firebaseCollection, customer?.id, "messages"),
        orderBy("timestamp", "desc"),
        endBefore(msgs[msgs.length - 1])
      )
    : query(
        collection(db, firebaseCollection, customer.id, "messages"),
        orderBy("timestamp", "desc"),
        limit(limitMsgs)
      );

  let unsubscribe = onSnapshot(q, (querySnapshot) => {
    let messages = [];

    querySnapshot.docChanges().forEach(async (change, i) => {
      if (change.type === "added") {
        messages.push(change.doc);
      }
    });
    callback(messages.reverse(), unsubscribe);
  });
}
// ------------------------------------------------------------------------------------------------
async function readMessages(firebaseCollection, user) {
  try {
    await updateDoc(doc(db, firebaseCollection, user.id), {
      is_read: true,
    });
  } catch (error) {
    console.log(error);
  }
}
// ------------------------------------------------------------------------------------------------
async function AddFirebaseDoc(firebaseCollection, userId, callback = () => {}) {
  try {
    await setDoc(doc(db, firebaseCollection, userId), {
      content: "",
      // 'timestamp': serverTimestamp(),
      sender: "0000",
      receiver: userId,
      isRead: true,
      type: 0,
      attachedFile: "",
    });
    callback();
  } catch (error) {
    console.log(error);
  }
}
// ------------------------------------------------------------------------------------------------
async function updateUserTo(firebaseCollection, user, text) {
  try {
    await setDoc(doc(db, firebaseCollection, user.id), {
      sender: "0000",
      // 'text_message': text,
      receiver: user.id,
      is_read: false,
      timestamp: serverTimestamp(),
      type: 0,
      attachedFile: "",
      content: text,
    });
  } catch (error) {
    console.log(error);
  }
}
// ------------------------------------------------------------------------------------------------
async function sendMessage(firebaseCollection, user, text) {
  try {
    await setDoc(doc(db, firebaseCollection, user.id), {
      sender: "0000",
      // 'text_message': text,
      receiver: user.id,
      is_read: false,
      timestamp: serverTimestamp(),
      type: 0,
      attachedFile: "",
      content: text,
    });
    await addDoc(collection(db, firebaseCollection, user?.id, "messages"), {
      sender: "0000",
      // 'text_message': text,
      receiver: user.id,
      is_read: false,
      timestamp: serverTimestamp(),
      type: 0,
      attachedFile: "",
      content: text,
    });
  } catch (error) {
    console.log(error);
  }
}
// ------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------=
const listenOnDoc = (id, setDoc) => {
  const unsub = onSnapshot(
    doc(db, process.env.REACT_APP_FIREBASE_DRIVERS_COLLECTION, id),
    (doc) => {
      // console.log("Current data: ", doc.data());
      setDoc(doc.data());
    }
  );
  return unsub;
};
// ------------------------------------------------------------------------------------------------
const paginateMsgs = async (
  firebaseCollection,
  lastVisibleMsg,
  customerId,
  callback
) => {
  const messages = [];
  const querySnapshot = await getDocs(
    query(
      collection(db, firebaseCollection, customerId, "messages"),
      orderBy("timestamp", "desc"),
      limit(process.env.REACT_APP_firebase_msgs_page_size),
      startAfter(lastVisibleMsg)
    )
  );
  let counter = 0;
  if (!querySnapshot.docs.length) {
    lastMsg = undefined;
  }
  querySnapshot.forEach((doc, i) => {
    counter++;

    if (counter === querySnapshot.docs.length) {
      lastMsg = doc;
    }
    // doc.data() is never undefined for query doc snapshots
    messages.push(doc);
    if (counter === querySnapshot.docs.length) {
      counter = 0;
    }
  });
  callback(messages.reverse(), lastMsg);
};
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
const paginateMsgsAsc = async (
  firebaseCollection,
  lastVisibleMsg,
  customerId,
  callback
) => {
  let messages = [];
  const querySnapshot = await getDocs(
    query(
      collection(db, firebaseCollection, customerId, "messages"),
      orderBy("timestamp", "desc"),
      endBefore(lastVisibleMsg)
    )
  );
  let counter = 0;
  querySnapshot.forEach((doc, i) => {
    counter++;
    if (counter === querySnapshot.docs.length) {
      lastMsg = doc;
    }
    // doc.data() is never undefined for query doc snapshots
    messages.push({ ...doc.data(), id: doc.id });
    if (counter === querySnapshot.docs.length) {
      counter = 0;
    }
  });
  callback(messages, lastMsg);
  messages = [];
};
// ------------------------------------------------------------------------------------------------
let prevFirebaseCollection;
async function getCustomers(firebaseCollection, setCustomers, callback) {
  return onSnapshot(
    query(
      collection(db, firebaseCollection),
      orderBy("timestamp", "desc"),
      limit(15)
      // process.env.REACT_APP_firebase_msgs_page_size
    ),
    (querySnapshot) => {
      let customers = [];
      let modifiedCustomers = [];
      const res = querySnapshot.docChanges().forEach((change, i) => {
        if (change.type === "added") {
          // console.log(change.doc.data().content);
          customers.push(change.doc);
        }
        if (change.type === "modified") {
          modifiedCustomers.push(change.doc);
        }
      });
      setCustomers((prev) => {
        if (prev[firebaseCollection]) {
          let prevCustemersAndAdded = [
            ...prev[firebaseCollection],
            ...customers,
          ];
          let customersWithoutmodefied = prevCustemersAndAdded.filter(
            (c) => !modifiedCustomers.find((el) => el.id === c.id)
          );

          prev[firebaseCollection] = [
            ...modifiedCustomers,
            ...customersWithoutmodefied,
          ];
          return { ...prev };
        }
        prev[firebaseCollection] = customers;
        return { ...prev };
      });
      setTimeout(() => {
        prevFirebaseCollection = firebaseCollection;
      }, 0);
      callback(customers);
    }
  );
}

// ------------------------------------------------------------------------------------------------
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
// ------------------------------------------------------------------------------------------------
const paginateCustomers = (customers, firebaseCollection, callback) => {
  return onSnapshot(
    query(
      collection(db, firebaseCollection),
      orderBy("timestamp", "desc"),
      limit(process.env.REACT_APP_firebase_msgs_page_size),
      startAfter(customers[customers?.length - 1])
    ),
    (querySnapshot) => {
      const customers = querySnapshot.docs;
      callback(customers);
    }
  );
};
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
const getcutomerObj = async (customer) => {
  const docRef = doc(db, "users-staging", customer?.id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
  } else {
    // doc.data() will be undefined in this case
    // console.log("No such document!");
  }
  return docSnap;
};

const getCustomerProfile = async (id, setCustomerProfile) => {
  const docRef = doc(db, process.env.REACT_APP_FIREBASE_users_collection, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    setCustomerProfile(docSnap.data());
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    setCustomerProfile();
  }
};

export {
  AddFirebaseDoc,
  updateUserTo,
  listenOnDoc,
  getCustomerProfile,
  getFirebaseDoc,
  paginateMsgsAsc,
  db,
  sendMessage,
  getMessages,
  readMessages,
  paginateMsgs,
  getCustomers,
  paginateCustomers,
  getcutomerObj,
};
