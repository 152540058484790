const Routes = {
  // LoginForm: "/",
  Home: "/",
  DrawingMap: "/drawing-map",
  SiteMap: "/map",
  DriverList: "/drivers",
  TripsList: "/trip",
  PassengersList: "/passengers",
  Station: "/station",
  PickLocation: "/pickup",
  DriverDetail: "/driverDetails",
  PassengerDetail: "/passengerdetails",
  TripDetails: "/tripdetails",
  PricingList: "/price",
  LoginPage: "/login",
  refactorPage: "/rp",
  anonTrip: "anontrip",
  UsersManagement: "users-management",
  general: "/general",
  carClassification: "/carClassification",
  pricing: "/pricing",
  faq: "/faq",
  driverShift: "/drivershift",
  driversWallet: "/driverwallet",
  driverPromotion: "driverpromotion",
  pWallet: "/pwallet",
  vehiclesManagement: "/vehiclesmanagement",
  carClasses: "/carclasses",
  cars: "/cars",
  scheduledTripetails: "/scheduledTripetails",
  scheduledTrips: "/Scheduledtrips",
  driverTransactionDetails: "/driver-transaction-details",
  passengerTransactionDetails: "/passenger-transaction-details",
  promoCode: "/promocode",
  chat: "/chat",
  DriversGroups: "/driversgroups",
  driversArchive: "/driversarchive",
  Passengersgroups: "/passengersgroups",
  passengersArchive: "/passengersarchive",
  UsersGroups: "/usersgroups",
  usersArchive: "/usersarchive",
  PassengersgroupDetails: "/passengersgroupdetails",
  DriversGroupsDetails: "/driversgroupdetails",
  UsersGroupsDetails: "/usersgroupdetails",
  RulesManagement: "/rulesmanagement",
  ShiftsManagement: "/shiftsmanagement",
  ScheduleTrips: "/scheduletrips",
  Garages: "/garages",
  GarageDetails: "/garagedetails",
  anonymousTriplayout: "/manual-dispatch",
  notificationsCenter: "/notifications-center",
  permissions: "/permissions",
  NotAuth: "/not-auth",
  StaffGroupDetails: "/staff-group-details",
  StaffLogsPage: "/staff-logs-page",
  NotificationHistory: "notification-history",
  GarageTripsList: "/garage-trip",
  ServingAreas: "/serving-areas",
};

export default Routes;
